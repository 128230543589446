<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-card :elevation="1">
                    <v-card-text class="py-1">
                        <v-row dense>
                            <v-col cols="12" md="2">
                                <v-combobox
                                    label="Department"
                                    dense
                                    outlined
                                    hide-details
                                    :items="$store.getters['department/departments']"
                                    v-model="department"
                                    item-text="name"
                                    item-value="id"
                                    :loading="$store.getters['department/loadingDepartments']"
                                    @focus="getDepartments"
                                    class="custom-form"
                                ></v-combobox>
                            </v-col>
                            
                            <v-col cols="12" md="2">
                                <v-combobox
                                    label="Designation"
                                    dense
                                    outlined
                                    hide-details
                                    :items="$store.getters['designation/designations']"
                                    v-model="designation"
                                    item-text="name"
                                    item-value="id"
                                    :loading="$store.getters['designation/loadingDesignations']"
                                    @focus="getDesignations"
                                    class="custom-form"
                                ></v-combobox>
                            </v-col>
                            
                            <v-col cols="12" md="2">
                                <v-combobox
                                    label="Shift"
                                    dense
                                    outlined
                                    hide-details
                                    :items="$store.getters['shift/shifts']"
                                    v-model="shift"
                                    item-text="name"
                                    item-value="id"
                                    :loading="$store.getters['shift/loadingShifts']"
                                    @focus="getShifts"
                                    class="custom-form"
                                ></v-combobox>
                            </v-col>

                            <v-col cols="12" md="2">
                                <v-combobox
                                    label="Employee"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="employee"
                                    :items="$store.getters['employee/employees']"
                                    :loading="$store.getters['employee/loadingEmployees']"
                                    item-text="display_name"
                                    item-value="id"
                                    class="mb-1 custom-form"
                                    @focus="getEmployees"
                                ></v-combobox>
                            </v-col>
                        
                            <v-col cols="12" md="2">
                                <v-menu
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            dense
                                            outlined
                                            hide-details
                                            v-model="date"
                                            label="Date"
                                            v-on="on"
                                            class="custom-form"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="6" md="1">
                                <v-btn
                                    elevation="1"
                                    small
                                    color="primary"
                                    dark
                                    style="margin-top:-2px"
                                    :loading="loading"
                                    @click="getResults"
                                > Search </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="results.length > 0">
            <v-col cols="12" md="12" class="custom-form py-1">
                <v-card :elevation="1">
                    <v-toolbar dense color="white" :elevation="1" height="40px">
                        <v-spacer></v-spacer>
                        <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model="inTime"
                            label="In"
                            type="time"
                            style="max-width:100px;"
                        ></v-text-field>

                        <v-text-field
                            dense
                            outlined
                            hide-details
                            v-model="outTime"
                            label="Out"
                            type="time"
                            class="mx-2"
                            style="max-width:100px;"
                        ></v-text-field>

                        <v-btn small color="info" :elevation="1" @click="setTime">Set</v-btn>

                    </v-toolbar>

                    <v-card-text class="pa-0">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">E.Id</th>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">Phone</th>
                                        <th class="text-left">Designation</th>
                                        <th class="text-left">Department</th>
                                        <th class="text-left">Shift</th>
                                        <th class="text-center">In</th>
                                        <th class="text-center">Out</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(employee, sl) in results" :key="sl">
                                        <td>{{ employee.emp_id }}</td>
                                        <td>{{ employee.name }}</td>
                                        <td>{{ employee.phone }}</td>
                                        <td>{{ employee.designation_name }}</td>
                                        <td>{{ employee.department_name }}</td>
                                        <td>{{ employee.shift_name }}</td>
                                        <td style="width:120px;">
                                            <v-text-field
                                                type="time"
                                                dense
                                                outlined
                                                hide-details
                                                v-model="employee.in_time"
                                                style="max-width:100px;"
                                            ></v-text-field>
                                        </td>
                                        <td style="width:120px;">
                                            <v-text-field
                                                type="time"
                                                dense
                                                outlined
                                                hide-details
                                                v-model="employee.out_time"
                                                style="max-width:100px;"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-btn icon color="primary" small @click="openMultiAttendanceDialog(sl)">
                                                <v-icon>mdi-plus</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="12" class="text-right">
                <v-btn height="30px" dark color="info" class="mr-1" :elevation="1" @click="saveAttendance">Save</v-btn>
                <v-btn height="30px" dark color="deep-orange" :elevation="1">Cancel</v-btn>
            </v-col>
        </v-row>

        <!-- attendance dialog start -->
        <v-dialog
            v-model="dialog"
            persistent
            max-width="500px"
        >
            <v-card>
                <v-card-title class="py-2">
                    Employee attendance
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mx-2"
                        fab
                        small
                        text
                        color="red"
                        @click="dialog = false"
                    >
                        X
                    </v-btn>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    <v-row>
                        <v-form class="custom-form">
                            <v-row dense>
                                <v-col cols="11">
                                    <v-row no-gutters>
                                        <v-col cols="4" class="pr-3">
                                            <v-text-field
                                                type="date"
                                                dense
                                                outlined
                                                hide-details
                                                v-model="emp.date"
                                                style="width:135px;"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4" class="pr-3">
                                            <v-text-field
                                                type="time"
                                                dense
                                                outlined
                                                hide-details
                                                v-model="emp.in_time"
                                                style="max-width:120px;"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field
                                                class="custom-form"
                                                type="time"
                                                dense
                                                outlined
                                                hide-details
                                                v-model="emp.out_time"
                                                style="max-width:120px;"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="1">
                                    <v-btn type="submit" color="primary" small @click.prevent="addCartItem">Add</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-col cols="12">
                            <table border="1px solid #ccc" class="custom-tbl">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>In Time</th>
                                        <th>Out Time</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(attendance, ind) in empCartInd != null ? results[empCartInd].attendances : []" :key="ind">
                                        <td>{{ attendance.date }}</td>
                                        <td>{{ attendance.in_time }}</td>
                                        <td>{{ attendance.out_time }}</td>
                                        <td>
                                            <v-btn icon color="red" small @click="removeCartItem(ind)"><v-icon>mdi-delete</v-icon></v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- attendance dialog end -->
    </v-container>
</template>
<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data(){
        return{
            date: this.toISOLocal().substr(0, 10),
            inTime: null,
            outTime: null,
            department: null,
            designation: null,
            shift: null,
            employee: null,
            results: [],
            loading: false,
            dialog: false,
            empCartInd: null,
            emp: {
                date: '',
                in_time: '',
                out_time: ''
            },
        }
    },
    watch: {
      date(){
        this.results = [];
      }  
    },
    methods: {
        openMultiAttendanceDialog(ind) {
            this.dialog = true;
            this.empCartInd = ind;
        },
        async addCartItem() {
            if(this.emp.date == '') {
                this.$store.dispatch('snackbar/error', 'Date is required');
                return;
            }
            if(this.emp.in_time == '') {
                this.$store.dispatch('snackbar/error', 'Employee in time is not empty');
                return;
            }
            if(this.emp.out_time == '') {
                this.$store.dispatch('snackbar/error', 'Employee out time is not empty');
                return;
            }
            
            
            let attendance = {
                date: this.emp.date,
                in_time: this.emp.in_time,
                out_time: this.emp.out_time
            }

            let cartInd = this.results[this.empCartInd].attendances.findIndex(d => d.date == attendance.date);
            if(cartInd > -1){
                this.results[this.empCartInd].attendances.splice(cartInd, 1);
            } 

            this.results[this.empCartInd].attendances.push(attendance)
            this.resetAttendaceForm();
        },
        resetAttendaceForm() {
            this.emp.date = '';
            this.emp.in_time = '';
            this.emp.out_time = '';
        },
        removeCartItem(sl) {
            this.results[this.empCartInd].attendances.splice(sl, 1)
            this.dialog = false;
            this.dialog = true;
        },
        getDepartments(){
            this.department = null;
            this.$store.dispatch('department/getDepartments');
        },
        getDesignations(){
            this.designation = null;
            this.$store.dispatch('designation/getDesignations');
        },
        getShifts(){
            this.shift = null;
            this.$store.dispatch('shift/getShifts');
        },
        getEmployees() {
            this.employee = null;
            this.$store.dispatch('employee/getEmployees', {
                apiParams: {
                    departmentId    : this.department?.id ?? null,
                    designationId   : this.designation?.id ?? null,
                    shiftId         : this.shift?.id ?? null,
                },
            })
        },
        async getResults(){
            this.inTime = null;
            this.outTime = null;
            this.results = [];
            this.loading = true;
            
            this.results = await this.$store
            .dispatch('attendance/getEmployeeAttendances', {
                apiParams: {
                    departmentId    : this.department?.id ?? null,
                    designationId   : this.designation?.id ?? null,
                    shiftId         : this.shift?.id ?? null,
                    employeeId      : this.employee?.id ?? null,
                    date            : this.date,
                },
                options: {
                    returnData : true
                }
            });

            this.results.map(item => {
                item.attendances = [];
                return item;
            })

            this.loading = false;
        },

        setTime() {
            this.results = this.results.map(employee => { 
                employee.in_time = this.inTime;
                employee.out_time = this.outTime;
                return employee;
            })
        },

        async saveAttendance() {
            let data = {
                date: this.date,
                attendances: this.results
            }

            let isSuccess = await this.$store.dispatch('attendance/saveAttendance', data);

            if(isSuccess){
                this.reset();
            }
        },
        reset(){
            this.inTime = null;
            this.outTime = null;
            this.department = null;
            this.designation = null;
            this.shift = null;
            this.employee = null;
            this.results = [];
            this.date = this.toISOLocal().substr(0, 10);
        }
    },
}
</script>
<style scoped>
    .custom-tbl {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
    }
</style>